'use strict';

import * as Common from "../common";
import {ControllerBase} from "../controllerBase";
import {ResultsController} from "../results/resultsController";

export class ClubController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'club_jerseycolors': this.jerseycolors,
      'club_lineup_game_id': this.lineup_entry,
      'club_locations': this.locations,
      'club_id_users': this.users,
      'club_id_results_id': this.results
    }
  }

  jerseycolors(): void {
    let $dialogPreview = $('#dialog-preview'),
      $dialogPreviewContent = $('#dialog-preview-content');

    $('.color').minicolors({
      theme: 'bootstrap'
    });

    $('form').dirtycheck()
      .on('submit', function (this: HTMLFormElement) {
        Common.prepareForSubmit(this);
      });

    $('.btnPreview').on('click', function () {
      let $prev = $(this).closest('td').prev();

      let c1 = ($prev.prev().find('input[type=text]').val()! as string).replace('#', '');
      let c2 = ($prev.find('input[type=text]').val()! as string).replace('#', '');

      $dialogPreviewContent.empty();
      $dialogPreviewContent.html('<span class="spinner-border mt-5"></span>');
      let img: HTMLImageElement = new Image();

      $(img).on('load', function (this: HTMLElement) {
        $(this).hide();
        $dialogPreviewContent.css('background', '').empty().append(this);
        $(this).fadeIn();
      })
        .on('error', function () {
          $dialogPreviewContent.html('Die Trikotvorschau konnte leider nicht geladen werden!')
        })
        .attr('src', '/image/jersey/' + c1 + '/' + c2 + '/');

      $dialogPreview.modal();

      return false;
    });
  }

  lineup_entry(): void {
    let $formLineup = $('#form-lineup');
    let jersyArray: Common.IDictionary<string> = $formLineup.data('jerseynumbers');
    let licenseArray: Common.IDictionary<string> = $formLineup.data('licenses');
    let $playerIds = $('select[name^="idPlayer["]'),
      $jerseyNumbers = $('input[name^="JersyNumber["]');

    function updateLicense(selectElement: HTMLSelectElement): void {
      let licenseNumber: string = (selectElement.value in licenseArray) ? licenseArray[selectElement.value] : '&nbsp;';
      $(selectElement).parent().next().html(licenseNumber);
      $(selectElement).parent().prev().children('input')
        .prop('disabled', selectElement.value == '');
    }

    $formLineup.validate({
      ignore: '.officials input', showErrors: function (errorMap, errorList) {
        $.each((this as any).successList, function (index, value) {
          $(value).removeClass('is-invalid');
          const $tr = $(value).parent().parent();
          $tr.removeClass('table-danger');
          $tr.children('td:last').html('');
          return true;
        });
        return $.each(errorList, function (index, value) {
          $(value.element).addClass('is-invalid');
          const $tr = $(value.element).parent().parent();
          $tr.addClass('table-danger');
          $tr.children('td:last').html('<div class="text-danger mr-2"><i class="fas fa-exclamation-circle mr-2"></i>' + value.message + '</div>');
          return true;
        });
      }
    });

    $jerseyNumbers.each(function () {
      $(this).rules('add', {
        range: [0, 99],
        unique: 'JersyNumber[',
        messages: {
          range: 'Die Trikotnummer muss eine Zahl zwischen 0 und 99 sein',
          unique: 'Die Trikotnummer ist mehrfach vorhanden'
        }
      });
    });

    $playerIds.each(function () {
      $(this).rules('add', {
        unique: 'idPlayer[',
        messages: {
          unique: 'Der Spieler wurde mehrfach ausgewählt'
        }
      });
    });

    $playerIds.select2({
      placeholder: '',
      width: '300px',
      allowClear: true
    });

    $playerIds.on('change', function (this: HTMLSelectElement) {
      $(this).parent().prev().children('input').val(jersyArray[this.value]);
      $formLineup.valid();
      updateLicense(this);
    });

    $playerIds.each(function () {
      updateLicense(this as HTMLSelectElement);
    });

    $formLineup.find('button[type=submit]').on('click', function () {
      $('input[type=hidden][name="download"]').val($(this).data('download'));
    });

    $jerseyNumbers.on('change', function () {
      let idPlayer = $(this).parent().next().children('select').val() as number;
      if (jersyArray[idPlayer] != undefined) {
        jersyArray[idPlayer] = $(this).val() as string;
      }
      $formLineup.valid();
    });

    jQuery.validator.addMethod('unique', function (value, element, params): boolean {
      if (value == '' || value == 'NULL') return true;
      let selector = jQuery.validator.format("[name!='{0}'][name^='{1}']", (element as HTMLSelectElement).name, params);
      let unique: boolean = true;
      $(selector).each(function (index, item): false | void {
        if (value == $(item).val()) {
          unique = false;
          return false;
        }
      });

      return unique;
    }, 'Der Wert ist bereits vorhanden.');

    let $validator = $('#form-copy-from').validate({
      rules: {
        'game': 'required'
      },
      messages: {
        'game': 'Bitte ein Spiel auswählen!',
      }
    });

    $('#games-copy-from tbody tr').on('click', function (event) {
      if (!(event.target instanceof HTMLInputElement)) {
        $(this).find('input[type=radio]').trigger('click');
      }
    });

    $('#dialog-copy-from').on('hide.bs.modal', function () {
      $validator.resetForm();
    });
  }

  locations(): void {
    $('select').select2({
      placeholder: 'Spielort auswählen...',
      allowClear: true
    });
  }

  users(): void {
    let $formUser = $('#form-user');

    let existingEmails = $('.existing-email').map(function () {
      return this.innerHTML.toUpperCase();
    }).toArray();

    jQuery.validator.addMethod("isnewemail", function (value) {
      return $.inArray(value.toUpperCase(), existingEmails) == -1;
    }, "Die angegebene E-Mail Adresse hat bereits Berechtigungen");

    $('.btn-delete-user').on('click', function () {
      if (confirm('Soll diese Berechtigung wirklich gelöscht werden?')) {
        $('#id-delete').val($(this).data('id'));
        $('#form-delete-user').trigger('submit');
      }
    });

    let $validator = $formUser.validate({
      rules: {
        email: {
          required: true,
          email: true,
          maxlength: 255,
          isnewemail: true
        },
        'userRights[]': 'required',
        comment: {
          maxlength: 1000
        }
      },
      messages: {
        email: {
          required: 'Bitte eine E-Mail Adresse eingeben',
          email: 'Bitte eine gültige E-Mail Adresse eingeben',
          maxlength: 'Die E-Mail Adresse darf maximal 255 Zeichen lang sein'
        },
        'userRights[]': 'Bitte mindestens eine Berechtigung auswählen!',
        comment: {
          maxlength: 'Die Bemerkung darf höchstens 1000 Zeichen lang sein'
        }
      }
    });

    $('#dialog-user').on('hide.bs.modal', function () {
      $validator.resetForm();
    });
  }

  results(): void {
    new ResultsController().entry();
  }
}
'use strict';

import * as Common from '../common';
import {AddItemsControl} from "../controls/addItemsControl";
import {ControllerBase} from "../controllerBase";

function showselect2(elementSelector: any, options?: any) {
  let elements = $(elementSelector);
  let url = elements.data('source-url');
  let dataCache: any = null;

  console.debug('source-url: ', url);

  (<any>$).fn.select2.amd.require(['select2/data/array', 'select2/utils'], function (ArrayData: any, Utils: any) {
    function CustomData(this: any, $element: any, options: any) {
      (<any>CustomData).__super__.constructor.call(this, $element, options);
    }

    Utils.Extend(CustomData, ArrayData);

    function filterResults(params: any, data: any) {
      if (params.term == undefined) {
        return data;
      }

      return {
        results: $.grep(data.results, function (a: any) {
          return a.text.toUpperCase().indexOf(params.term.toUpperCase()) !== -1;
        })
      };
    }

    CustomData.prototype.query = function (params: any, callback: any) {
      if (dataCache == null) {
        (<any>$).ajax({
          url: url
        }).done(function (data: any) {
          dataCache = data;
          callback(filterResults(params, dataCache));
        }).fail(function (jqXHR: any, textStatus: string, errorThrown: any) {
          console.error('Ajax to ' + url + ' failed.', jqXHR, textStatus, errorThrown);
          //BRIV.jsonFail
        });
      } else {
        callback(filterResults(params, dataCache));
      }
    };

    $.extend(options, {dataAdapter: CustomData});

    elements.select2(options);
  });
}


export class UserController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_user_id_admin': this.entry_admin,
      'admin_user_id_admin_leagues': this.entry_admin_leagues,
      'admin_user_id': this.entry_details,
      'admin_user_id_details': this.entry_details,
      'admin_user_create': this.entry_details,
      'admin_user_adminuser': this.list_admin_users,
      'admin_user': this.index
    };
  }

  entry_admin(): void {
    const $formUser = $('#form-user');

    // exit when form does not exist => user is no admin user
    if ($formUser.length == 0) {
      return;
    }

    const $roles = $('input[name^=idRole]'),
      $rolesCount = $('#roles-count');


    function updateRoleColor(item: HTMLInputElement) {
      $(item).parent().css('background-color', item.checked ? 'lime' : '');
    }

    function updateRolesCount() {
      $rolesCount.html($roles.filter(':checked').length.toString());
    }

    showselect2('#contact', {
      placeholder: 'Kontakt auswählen...',
      allowClear: true,
      templateResult: function (result: any): string | JQuery | null {
        if (!result.id) {
          return result.text;
        }

        return $(document.createElement('span')).html(result.text + '<br><small>' + result.email + '</small>');
      }
    });

    $formUser
      .dirtycheck({filter: 'input'})
      .validate({
        rules: {
          EMailBRIV: 'email',
          idKontakt: 'required'
        },
        messages: {
          EMailBRIV: 'Die angegebene E-Mail Adresse ist ungültig!',
          idKontakt: 'Bitte einen Kontakt auswählen!'
        }
      });


    $roles.on('click', function (this: HTMLInputElement) {
      updateRoleColor(this);
      updateRolesCount();
    })
      .each(function () {
        updateRoleColor(this as HTMLInputElement);
      });

    updateRolesCount();
  }

  entry_admin_leagues(): void {
    new AddItemsControl();
  }

  entry_details(): void {
    $('#form-user')
      .dirtycheck()
      .validate({
        rules: {
          FirstName: 'required',
          LastName: 'required',
          EMail: {email: true, required: true},
        },
        messages: {
          FirstName: 'Bitte einen Vornamen angeben',
          LastName: 'Bitte einen Nachnamen angeben',
          EMail: 'Die angegebene E-Mail Adresse ist ungültig'
        }
      });
  }

  index(): void {
    $('table').on('init.dt', function () {
      Common.setFocusDataTables();
    }).DataTable({
      language: {
        searchPlaceholder: 'Benutzer suchen...'
      }
    });
  }

  list_admin_users(): void {
    let chkShowAll = $('#chk-show-all');
    let subtitle = $('#subtitle');
    let additionalFilters = $('#additional-filter');

    $.fn.dataTable.ext.search.push(
      function (settings: any, data: any) {
        return chkShowAll.is(':checked') || data[6] == "1";
      }
    );

    let table = $('#table-user').on('init.dt', function () {
      Common.setFocusDataTables();
      $('.dataTables_filter').parent().append(additionalFilters);
      additionalFilters.show();
    }).DataTable({
      order: [[0, 'asc']],
      language: {
        searchPlaceholder: 'Benutzer suchen...'
      },
      columnDefs: [
        {
          targets: 4,
          searchable: false
        }
      ]
    });

    function updateSubtitle(): void {
      subtitle.html(chkShowAll.is(':checked') ? 'Alle Benutzer' : 'Nur aktive Benutzer');
    }

    updateSubtitle();

    chkShowAll.on('change', function () {
      updateSubtitle();
      table.draw();
    });
  }
}
'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class ClubController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_club_id': this.entry_details,
      'admin_club_id_details': this.entry_details,
      'admin_club_create': this.entry_details,
      'admin_club_id_players': this.entry_players,
      'admin_club_id_teams': this.entry_teams,
      'admin_club': this.index,
    };
  }

  entry_details(): void {
    $('#homepage').on('keyup', function (this: HTMLInputElement) {
      $('#homepageLink').attr('href', this.value);
    }).trigger('keyup');

    $('select').select2({placeholder: 'Kontakt auswählen...', allowClear: true});

    $('#form-club')
      .dirtycheck({message: 'Der Verein wurde noch nicht gespeichert!'})
      .validate({
        rules: {
          VereinName: 'required',
          Homepage: 'url'
        },
        messages: {
          VereinName: 'Bitte einen Vereinsnamen angeben!',
          Homepage: 'Der Link zur Homepage ist nicht gültig!'
        }
      });
  }

  entry_players(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          language: {
            searchPlaceholder: 'Spieler suchen...'
          }
        }
      );
  }

  entry_teams(): void {
    $('form').dirtycheck()
      .on('submit', function (this: HTMLFormElement) {
        Common.prepareForSubmit(this);
      });
  }

  index(): void {
    let chkShowAll = $('#chk-show-all');
    let subtitle = $('#subtitle');
    let additionalFilters = $('#additional-filter');

    $.fn.dataTable.ext.search.push(
      function (settings: any, data: any) {
        return chkShowAll.is(':checked') || data[5] == "1";
      }
    );

    let table = $('table').on('init.dt', function () {
      Common.setFocusDataTables();
      $('.dataTables_filter').parent().append(additionalFilters);
      additionalFilters.show();
    }).DataTable({
        language: {
          searchPlaceholder: 'Vereine suchen...'
        }
      }
    );

    function updateSubtitle(): void {
      subtitle.html(chkShowAll.is(':checked') ? 'Alle Vereine' : 'Nur aktive Vereine');
    }

    updateSubtitle();

    chkShowAll.on('change', function () {
      updateSubtitle();
      table.draw();
    });
  }
}
'use strict';

import * as Common from "../common";
import Sortable = require("sortablejs");
import {ControllerBase} from "../controllerBase";

export class DocumentController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_document_create': this.upload,
      'admin_document_download_overview': this.download_overview,
      'admin_document_id': this.entry,
      'admin_document': this.index
    };
  }

  download_overview(): void {
    let _isDirty = false;

    const options = {
      animation: 150,
      ghostClass: 'list-group-item-secondary',
      onEnd: function (evt: any) {
        _isDirty = true;
        $(evt.from).children().each(function (index) {
          $(this).children('input').val(index);
        });
      }
    };

    $('.sortable').each(function (this: HTMLElement) {
      Sortable.create(this, options);
    });

    $('#form-downloadoverview').on('submit', function () {
      _isDirty = false;
    });

    $(window).on('beforeunload', function () {
      if (_isDirty) {
        return 'Die Änderung der Reihenfolge wurde noch nicht gespeichert!';
      }
      return '';
    });
  }

  entry(): void {
    $('#document-category').select2({
      placeholder: 'Kategorie auswählen...',
      allowClear: true
    });

    $('#form-document').dirtycheck({message: 'Das Dokument wurde noch nicht gespeichert!'})
      .validate({
        rules: {
          DocumentName: 'required',
          DocumentFileName: 'required'
        },
        messages: {
          DocumentName: 'Es muss ein Dokumentname angegeben werden!',
          DocumentFileName: 'Es muss ein Dateiname angegeben werden!'
        }
      });
  }

  index(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          order: [[4, 'desc']],
          language: {
            searchPlaceholder: 'Dokumente suchen...'
          }
        }
      );
  }

  upload(): void {
    $.validator.addMethod('filesize', function (this: any, value, element, param) {
      const fileElement = <HTMLInputElement>element;
      if (fileElement === null) return true;

      // @ts-ignore
      return this.optional(element) || (fileElement.files[0].size <= param)
    });

    $('#form-document').validate({
      rules: {
        document: {
          required: true,
          filesize: 10485760
        }
      },
      messages: {
        document: {
          required: 'Bitte eine Datei zum hochladen auswählen!',
          filesize: 'Die maximale Dateigröße beträgt 10 MB!'
        }
      }
    });
  }
}
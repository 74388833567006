'use strict';

import jqXHR = JQuery.jqXHR;

export interface IDictionary<T> {
  [index: string]: T;
}

export function prepareForSubmit(form: HTMLFormElement, message?: string): void {
  $('button').prop('disabled', true);
  prepareButtonForSubmit($(form).find('button[type="submit"]').first() as JQuery<HTMLButtonElement>, message);
}

export function prepareButtonForSubmit($btn: JQuery<HTMLButtonElement>, message?: string): void {
  if (message === undefined) {
    message = $btn.data('submit-text');
    if (message === undefined) {
      message = 'Wird gespeichert...';
    }
  }

  $btn.html(`<span class="spinner-border" role="status" aria-hidden="true"></span>${message}`);
}

export function setFocusDataTables(): void {
  $('div.dataTables_filter input').trigger('focus');
}

export function showJsonFailError(jqxhr: jqXHR, textStatus: string, error: string): void {
  alert('Fehler beim holen der Daten!\n\nFehlermeldung: ' + error + '\nFehlercode: ' + jqxhr.status);
}

export function showLoading(control: HTMLElement, text: string): void {
  control.innerHTML = `<div class="loading text-secondary"><span class="spinner-border mr-2"></span>${text}</div>`;
}

export function showErrorMessage(message: string): void {
  $('#error-message').html(message);
  $('#error-container').removeClass('d-none');
}

export function cloneInputAndSelect(form: JQuery, elements: JQuery): void {
  form.append(elements.filter('input').clone().hide() as any as JQuery<HTMLElement>);

  elements.filter('select').each(function () {
    const select = this as HTMLInputElement;
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = select.name;
    input.value = select.value;
    form.append(input);
  });
}
'use strict';

export {};

let _isDirty: boolean = false;
let init = function ($this: HTMLFormElement, options: DirtycheckOptions) {
  let settings = $.extend({
    message: 'Die Änderungen wurden noch nicht gespeichert!',
    filter: ':input',
    isDirty: false
  }, options);

  $this.on('submit', function () {
    _isDirty = false;
  });

  $this.find(settings.filter).change(function () {
    _isDirty = true;
  });

  $(window).on('beforeunload', function () {
    if (_isDirty) {
      return settings.message;
    }

    return undefined;
  });

  return $this;
};

$.fn.dirtycheck = function (this: HTMLFormElement, methodOrOptions: string | DirtycheckOptions): JQuery {
  if (typeof methodOrOptions === 'string') {
    if (methodOrOptions == 'dirty') {
      _isDirty = true;
    } else if (methodOrOptions == 'clean') {
      _isDirty = false;
    }
    else {
      console.error('Dirtycheck: Unknown method \'' + methodOrOptions + '\'');
    }
  }
  else {
    init(this, methodOrOptions);
  }

  return $(this) as JQuery<HTMLElement>;
};
'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class RefereeController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_referee_duplicates': this.duplicates,
      'admin_referee_id_additionalgames': this.entry_additionalgames,
      'admin_referee_id_clubs': this.entry_clubs,
      'admin_referee_id': this.entry_details,
      'admin_referee_id_details': this.entry_details,
      'admin_referee_create': this.entry_details,
      'admin_referee_id_games': this.entry_games
    };
  }

  duplicates(): void {
    $('button').on('click', function () {
      const mergeTo = $(this).data('id');
      $(this).closest('form').find('input[type=hidden][name=mergeTo]').val(mergeTo);
    });
  }

  entry_additionalgames(): void {
    $('.btn-save').on('click', function () {
      const clone = $(this).closest('tr').find('input').clone();
      $('#form-edit').append(clone as any).trigger('submit');
    });

    $('.btn-delete').on('click', function () {
      if (confirm('Soll dieser Schiedsrichtereinsatz wirklich gelöscht werden?')) {
        $('#idadd-delete').val($(this).data('id'));
        $('#form-delete').trigger('submit');
      }
      return false;
    });

    $('input[type=text][name=date]').datepicker();
  }

  entry_clubs(): void {
    $('.btn-save').on('click', function () {
      const $form = $('#form-edit');
      Common.cloneInputAndSelect($form, $(this).closest('tr').find('input, select') as any);
      $form.trigger('submit');
    });

    $('.btn-delete').on('click', function () {
      if (confirm('Soll dieser Eintrag wirklich gelöscht werden?')) {
        $('input[name=idSchiedsrichterVerein]').val($(this).data('id'));
        $('#form-delete').trigger('submit');
      }
      return false;
    });
  }

  entry_details(): void {

    const $formReferee = $('#form-referee'),
      $btnSave = $('#btn-save');

    const idReferee = $formReferee.data('id-referee'),
      count = $formReferee.data('referee-games-count') +
        $formReferee.data('referee-additional-addignment-count') +
        $formReferee.data('timekeeper-games-count'),
      currentSaveButtonHtml = $btnSave.html();

    $btnSave.on('click', function () {
      Common.prepareForSubmit($formReferee[0] as HTMLFormElement, 'Prüfe E-Mail Adresse...');
    });

    $('#btn-delete').on('click', function () {
      if (count > 0) {
        alert('Der Schiedsrichter / Zeitnehmer hat bereits ' + count + ' Einsätze und kann nicht gelöscht werden!');
        return false;
      }
      if (confirm('Soll dieser Schiedsrichter wirklich gelöscht werden?')) {
        $formReferee.dirtycheck('clean');
        $('#form-referee-delete').trigger('submit');
      }
      return false;
    });

    $('#date-of-birth').datepicker();

    $formReferee.dirtycheck()
      .validate({
        invalidHandler: function (event, validator) {
          $btnSave.html(currentSaveButtonHtml);
          $('button').prop('disabled', false);
        },
        rules: {
          FirstName: 'required',
          LastName: 'required',
          EMail: {
            email: true,
            remote: {
              url: $formReferee.data('check-email-url'),
              type: 'post',
              data: {
                id: function () {
                  return idReferee;
                }
              }
            }
          },
          DateOfBirth: {required: false, dateDE: true},
          LicenseNumber: 'digits'
        },
        messages: {
          FirstName: 'Bitte Vornamen angeben!',
          LastName: 'Bitte Nachnamen angeben!',
          EMail: {email: 'Das Format der E-Mail Adresse ist nicht korrekt! (evtl. Leerzeichen entfernen)', remote: ''},
          DateOfBirth: {dateDE: 'Das Geburtsdatum ist ungültig!'},
          LicenseNumber: 'Die Passnummer ist keine gültige Zahl!'
        }
      });
  }

  entry_games(): void {
    $('#table-games').DataTable();
  }

  index(): void {
    const $formReferees = $('#form-referees');

    $('#btn-export').on('click', function () {
      const url = $formReferees.attr('action') as string;
      $formReferees.attr('action', $formReferees.data('export-url'));
      $formReferees.trigger('submit');
      $formReferees.attr('action', url);
    });
  }
}
'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class SecureController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'secure_login': this.login,
      'secure_register': this.register,
      'secure_password_change': this.password_change,
      'secure_password_reset': this.password_reset,
      'secure_password_reset_process': this.password_reset_user_selection
    }
  }

  login(): void {
    $('#form-login').validate({
      rules: {
        _username: {required: true},
        _password: {required: true}
      },
      messages: {
        _username: 'Bitte E-Mail eingeben',
        _password: 'Bitte Passwort eingeben'
      }
    });

    if (document.cookie && document.cookie.indexOf('cookieCheck=ok') != -1) {
      document.cookie = 'cookieCheck=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
    } else {
      Common.showErrorMessage('Für den BRIV Login mussen Cookies aktiviert sein. Aktivieren Sie Cookies und <a href="" class="alert-link"><i class="fas fa-redo mr-2"></i>aktualisieren</a> Sie anschließend diese Seite.');
    }
  }

  password_change(): void {
    $('#form-change-password').validate({
      rules: {
        passwordOld: 'required',
        passwordNew: {
          required: true,
          minlength: 8
        },
        passwordNew2: {
          required: true,
          equalTo: '#password-new'
        }
      },
      messages: {
        passwordOld: 'Bitte altes Passwort eingeben',
        passwordNew: {
          required: 'Bitte neues Passwort eingeben',
          minlength: 'Das neue Passwort muss mindestens 8 Zeichen lang sein'
        },
        passwordNew2: {
          required: 'Bitte neues Passwort wiederholen',
          equalTo: 'Die neuen Passwörter stimmen nicht überein'
        }
      }
    });
  }

  password_reset(): void {
    $('#form-password-reset').validate({
      rules: {
        email: {
          required: true,
          email: true
        }
      },
      messages: {
        email: {
          required: 'Bitte E-Mail-Adresse eingeben',
          email: 'Bitte eine gültige E-Mail Adresse eingeben'
        }
      }
    });
  }

  password_reset_user_selection(): void {
    $('#form-password-reset').validate({
      rules: {
        idUser: {
          required: true
        }
      },
      messages: {
        idUser: {
          required: 'Bitte einen Benutzer auswählen'
        }
      }
    });
  }

  register(): void {
    $('#form-register').validate({
      rules: {
        firstname: {required: true},
        lastname: {required: true},
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 8
        }
      },
      messages: {
        firstname: 'Bitte einen Vornamen eingeben',
        lastname: 'Bitte einen Nachnamen eingeben',
        email: {
          required: 'Bitte eine E-Mail Adresse eingeben',
          email: 'Bitte eine gültige E-Mail Adresse eingeben'
        },
        password: {
          required: 'Bitte ein Passwort eingeben',
          minlength: 'Das Passwort muss mindestens 8 Zeichen lang sein.'
        }
      }
    });
  }
}
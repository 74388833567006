'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class ContactController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_contact_id': this.entry,
      'admin_contact_create': this.entry,
      'admin_contact': this.index
    };
  }

  entry(): void {
    $('#form-contact')
      .dirtycheck({message: 'Der Kontakt wurde noch nicht gespeichert!'})
      .validate({
        rules: {
          Vorname: 'required',
          Nachname: 'required',
          PLZ: {range: [10000, 99999]},
          eMail: 'email'
        },
        messages: {
          Vorname: 'Bitte einen Vornamen eingeben',
          Nachname: 'Bitte einen Nachnamen eingeben',
          PLZ: 'Die Postleitzahl ist ungültig',
          eMail: 'Das Format der E-Mail Adresse ist nicht korrekt (evtl. Leerzeichen entfernen)'
        }
      });
  }

  index(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          language: {
            searchPlaceholder: 'Kontakte suchen...'
          }
        }
      );
  }
}
'use strict';

export abstract class ControllerBase {
  protected routes: { [id: string]: () => void } = {};

  process(route: string): boolean {
    if (this.routes[route] === undefined) return false;
    this.routes[route]();
    return true;
  }

  checkRoutesStartWithKey(key: string) {
    for(let route in this.routes) {
      if (!route.startsWith(key)) {
        console.error(`Route '${route}' does not start with '${key}'`);
      }
    }
  }
}
'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class NewsController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_news_id': this.entry,
      'admin_news_create': this.entry,
      'admin_news': this.index
    };
  }

  entry(): void {
    let $formNews = $('#form-news'),
      $isPinned = $('#is-pinned'),
      $pinnedUntilDate = $('#pinned-until-date');

    let onIsPinnedChecked = function () {
      let isPinned = $isPinned.is(':checked');
      if (isPinned) {
        if (($pinnedUntilDate.val() as string).length == 0) {
          let dateplus30 = new Date();
          dateplus30.setDate(new Date().getDate() + 30);
          $pinnedUntilDate.datepicker('update', dateplus30);
        }
        $pinnedUntilDate.prop('disabled', false);
      } else {
        $pinnedUntilDate.prop('disabled', true);
      }
    };

    CKEDITOR.replace('news-content', {
      resize_dir: 'both',
      resize_minHeight: 400,
      resize_minWidth: 780,
      height: 500,
      width: 780,
      contentsCss: '/css/ckeditor.css',
      filebrowserBrowseUrl: $formNews.data('doc-ckebrowse-url'),
      filebrowserUploadUrl: $formNews.data('doc-ckeupload-url'),
      filebrowserImageBrowseUrl: $formNews.data('img-ckebrowse-url'),
      filebrowserImageUploadUrl: $formNews.data('img-ckeupload-url')
    }).on('change', function () {
      $formNews.dirtycheck('dirty');
    });

    $formNews
      .dirtycheck({message: 'Die Newsmeldung wurde noch nicht gespeichert!'})
      .validate({
        rules: {
          Title: 'required',
          PinnedUntilDate: {
            required: 'input[type=checkbox][name=isPinned]:checked',
            dateDE: true
          }
        },
        messages: {
          Title: 'Bitte Titel angeben!',
          PinnedUntilDate: {
            required: 'Bitte ein Datum angeben!',
            dateDE: 'Das Datum ist ungültig!'
          }
        }
      });

    $pinnedUntilDate.datepicker();

    $isPinned.on('change', onIsPinnedChecked);
    onIsPinnedChecked();
  }

  index(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          order: [[0, 'desc']],
          language: {
            searchPlaceholder: 'News suchen...'
          }
        }
      );
  }
}
'use strict';

import * as Common from "../common";
import {ControllerBase} from "../controllerBase";

export class IshdController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_ishd_pending': this.pending,
      'admin_ishd_raw_player': this.rawPlayer,
      'admin_ishd_raw_player_id': this.rawPlayerEntry
    };
  }

  pending(): void {
    let $dialog = $('#select-dialog');
    let $update_pending_details = $('#update-pending-details');
    let $possible_players = $('#possible-players');

    $('button.select-player').on('click', function () {
      let url = $(this).data('players-url');
      let $tdlist = $(this).parent().parent().children();
      let id = $($tdlist[0]).text();

      $update_pending_details.html($($tdlist[6]).text() + ' ' + $($tdlist[5]).text() + ' ' + $($tdlist[7]).text() + ' ' + $($tdlist[3]).text());
      Common.showLoading($possible_players[0], 'Loading possible players...');

      $('input[name=idIshdUpdatePending]').val(id);
      $dialog.modal();
      $.getJSON(url, '', function (data: any) {
        let maxMatchCount = 0;
        for (let i = 0; i < data.length; i++) {
          if (data[i].MatchCount > maxMatchCount) {
            maxMatchCount = data[i].MatchCount;
            if (maxMatchCount == 4) break;
          }
        }

        $possible_players.html('<div class="checkbox"><label><input type="radio" name="idSpieler" value="-1"' + (maxMatchCount < 3 ? ' checked="checked"' : '') + '> Spieler neu anlegen</label></div>');

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            let player = data[i];
            //console.log(player);
            let li = '<div class="checkbox"><label><input type="radio" name="idSpieler" value="' + player.idSpieler + '"> ';
            li += player.MatchCount + ' ' + player.Vorname + ' ' + player.Nachname + ' ' + player.Geburtsdatum + ' ';
            if (player.VereinName == 'null') {
              li += '[kein Verein]';
            } else {
              li += player.VereinName
            }
            li += ' (idSpieler: ' + player.idSpieler + ')</label></div>';
            $possible_players.append(li);
          }
        }
      })
        .fail(Common.showJsonFailError);
    });
  }

  rawPlayer(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          language: {
            searchPlaceholder: 'Spieler oder Passnummer suchen...'
          }
        }
      );
  }

  rawPlayerEntry(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          language: {
            searchPlaceholder: 'Datum oder Team suchen...'
          }
        }
      );
  }
}
'use strict';

import * as Common from "../common";
import {LazyDataAdapter} from '../LazyDataAdapter';
import {DataFormat} from "select2";
import {ControllerBase} from "../controllerBase";

export class GameController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_game_id': this.entry_details,
      'admin_game_id_details': this.entry_details,
      'admin_game_id_goals': this.entry_goals,
      'admin_game_id_goalies': this.entry_goalies,
      'admin_game_id_penalties': this.entry_penalties,
      'admin_game_id_players': this.entry_players,
      'admin_game_league_id': this.index,
      'admin_game_week': this.index,
      'admin_game_week_day': this.index
    };
  }

  entry_details(): void {

    const $gameStatus = $('#game-status'),
      $unknownGameDate = $('#unknown-gamedate'),
      $gameDate = $('#game-date'),
      $gameTime = $('#game-time'),
      $gameWeekday = $('#game-weekday'),
      $labelGameTime = $('label[for="game-time"]'),

      $formGame = $('#form-game'),
      $dialogNotify = $('#dialog-notify'),
      $dialog_notify_btn_save = $('#dialog-notify-btn-save'),
      $travelCostsSplit = $('select#travel-costs-split'),
      $travelCostsSplitGameLink = $('#travel-costs-split-game-link');

    const _year = $formGame.data('year'),
      _dateNow = $formGame.data('date-now'),
      _refereeFees = $formGame.data('referee-fees');

    let _goalsHome = $('#goals-home').val() as string,
      _goalsAway = $('#goals-away').val() as string,
      _prevSpielStatus = $gameStatus.find('option:selected').val(),
      _gamedateCurrent: string | null = null,

      _refereeClubCurrent = $('#referee-club').val(),
      _refereeClubCurrent2 = $('#referee-club2').val(),
      _referee1Current = $('#referee1').val(),
      _referee2Current = $('#referee2').val();

    const gameTimes: string[] = [];
    for (let i = 9; i < 22; i++) {
      gameTimes.push(i + ':00');
      gameTimes.push(i + ':30');
    }

    _gamedateCurrent = getCurrentGameDate();

    function onChangeUnknownGameDate() {
      const isUnknown: boolean = $unknownGameDate.is(':checked');

      ($gameDate[0] as HTMLInputElement).disabled = isUnknown;
      ($gameTime[0] as HTMLInputElement).disabled = isUnknown;

      if (isUnknown) {
        $gameWeekday.addClass('text-muted');
        $labelGameTime.addClass('text-muted');
      } else {
        $gameWeekday.removeClass('text-muted');
        $labelGameTime.removeClass('text-muted');
      }
    }

    function onChangeGameDate() {
      $gameWeekday.html($gameDate.datepicker('getFormattedDate', 'DD'));
    }

    function onChangeSpielStatus() {
      const goalsHomeInput = $<HTMLInputElement>('#goals-home')[0],
        goalsAwayInput = $<HTMLInputElement>('#goals-away')[0],
        selectedGameStatus = $gameStatus.val();

      if (_prevSpielStatus == 1 || _prevSpielStatus == 2) {
        _goalsHome = goalsHomeInput.value;
        _goalsAway = goalsAwayInput.value;
      }

      if (selectedGameStatus == 0 || selectedGameStatus == 3) {
        goalsHomeInput.value = '';
        goalsAwayInput.value = '';
      }

      if (selectedGameStatus == 1 || selectedGameStatus == 2) {
        if (_goalsHome.length != 0) goalsHomeInput.value = _goalsHome;
        if (_goalsAway.length != 0) goalsAwayInput.value = _goalsAway;
      }

      if (selectedGameStatus == 5) {
        goalsHomeInput.value = '10';
        goalsAwayInput.value = '0';
      }

      if (selectedGameStatus == 6) {
        goalsHomeInput.value = '0';
        goalsAwayInput.value = '10';
      }

      _prevSpielStatus = selectedGameStatus;
    }

    function getCurrentGameDate() {
      let gameDate = null;
      if (!$unknownGameDate.is(':checked')) {
        const parts = ($gameTime.val() as string).split(':');
        gameDate = $gameDate.datepicker('getDate').getTime() + ((parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10)) * 60000);
      }

      return gameDate;
    }

    function updateRefereeFees() {
      let text,
        rank,
        refereeFeesGame = 0;
      for (let i = 1; i <= 2; i++) {
        text = '&nbsp;';
        rank = $('#referee' + i).data('referee-rank');

        if (rank > 0) {
          refereeFeesGame += parseInt(_refereeFees[rank]);
          text = 'Schiedsrichter Stufe ' + rank + ' (Spielgebühren: ' + (_refereeFees[rank] / 100).toFixed(2).replace('.', ',') + ' €)';
        }

        $('#referee' + i + '-details').html(text);
      }

      $('#referee-fees-game').html((refereeFeesGame / 100).toFixed(2).replace('.', ','));
    }

    function getLocalStorageKey(id: string): string {
      return 'admin.game.details.' + id + '.collapsed';
    }

    $gameTime.typeahead({
      source: gameTimes
    });

    $('div.collapse').each(function () {
      try {
        if (localStorage[getLocalStorageKey(this.id)] == '0') {
          $('#' + this.id).collapse('show');
        }
      } catch (e) {
        // empty
      }
    }).on('shown.bs.collapse', function (this: HTMLElement) {
      try {
        localStorage[getLocalStorageKey(this.id)] = '0';
      } catch (e) {
        // empty
      }
    }).on('hidden.bs.collapse', function (this: HTMLElement) {
      try {
        localStorage[getLocalStorageKey(this.id)] = '1';
      } catch (e) {
        // empty
      }
    });

    $unknownGameDate.on('change', onChangeUnknownGameDate).on('keyup', onChangeUnknownGameDate);
    onChangeUnknownGameDate();

    updateRefereeFees();

    $gameDate.datepicker({
      forceParse: false,
      startDate: new Date(_year, 1, 1),
      endDate: new Date(_year, 11, 31)
    });

    $gameDate.on('change', onChangeGameDate).on('keyup', onChangeGameDate);
    onChangeGameDate();

    $.validator.addMethod('time', function (value) {
      return value == '' || value.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/);
    });

    $gameStatus.on('change', onChangeSpielStatus);

    $travelCostsSplitGameLink.on('click', function (e) {
      if ($travelCostsSplitGameLink.attr('href') == '#') {
        e.preventDefault();
      }
    });

    $travelCostsSplit.on('change', function (this: HTMLInputElement) {
      if (this.value.length > 0) {
        $travelCostsSplitGameLink.attr('href', '/admin/game/' + this.value + '/');
        $travelCostsSplitGameLink.css('cursor', 'pointer').attr('title', 'Spielbericht öffnen').fadeTo(0, 1);
      } else {
        $travelCostsSplitGameLink.attr('href', '#');
        $travelCostsSplitGameLink.css('cursor', 'default').attr('title', '').fadeTo(0, 0.4);
      }
    }).trigger('change');

    $('#dialog-notify-content').on('click', '#notifyGameDateChanged, #notifyRefereeChanged',
      function () {
        const $this = $(this);
        const $div = $this.parent().next();
        if ($this.is(':checked')) {
          $div.show();
        } else {
          $div.hide();
        }
      }
    );


    $.fn.select2.amd.require(['select2/utils', 'select2/data/array'], function (Utils: any, ArrayAdapter: any) {
      const adapter = Utils.Decorate(ArrayAdapter, LazyDataAdapter);

      let select2Options = {
        dataAdapter: adapter
      };

      $('#game-type').select2(select2Options);

      $.extend(select2Options, {placeholder: ' ', allowClear: true});
      $('#referee-club, #referee-club2, #location').select2(select2Options);

      $('#timekeeper1, #timekeeper2').select2(select2Options);

      $('#referee1, #referee2').select2(select2Options).on('change', function (this: HTMLInputElement/* event, item */) {
        let rank = 0;
        if (this.value.length > 0) {
          rank = $('#referee1').data('select2-cache-data').filter((x: DataFormat) => x.id == this.value)[0].rank;
        }

        $(this).data('referee-rank', rank);
        updateRefereeFees();
      });
    });

    $gameStatus.select2();
    $travelCostsSplit.select2();


    $('#goals-home, #goals-away').on('keyup', function (this: HTMLInputElement) {
      if ($gameStatus.val() == 0 && this.value.length > 0) {
        $gameStatus.val(1);
      }
    });

    $formGame.dirtycheck({message: 'Die Spielberichtdetails wurden noch nicht gespeichert!'});

    const validator = $formGame.validate({
      rules: {
        GameDate: {
          required: 'input[type=checkbox][name=unknown-gamedate]:not(:checked)',
          dateDE: true
        },
        GameTime: {
          required: 'input[type=checkbox][name=unknown-gamedate]:not(:checked)',
          time: true
        },
        ToreHeim: 'digits',
        ToreGast: 'digits'
      },
      messages: {
        GameDate: {
          required: 'Bitte ein Spieldatum angeben!',
          dateDE: 'Das Spieldatum ist ungültig!'
        },
        GameTime: {
          required: 'Bitte eine Spielzeit angeben!',
          time: 'Die Spielzeit ist ungültig!'
        },
        ToreHeim: 'Ungültige Zahl!',
        ToreGast: 'Ungültige Zahl!'
      }
    });

    $dialog_notify_btn_save.on('click', function () {
      if ($('#notifyGameDateChanged').is(':checked') && $('input[name^=ngdc]:checked').length == 0) {
        alert('Bitte mindestens einen Empfänger bei der Benachrichtigung über Änderung des Spieldatums auswählen!');
        return false;
      }

      if ($('#notifyRefereeChanged').is(':checked') && $('input[name^=nrc]:checked').length == 0) {
        alert('Bitte mindestens einen Empfänger bei der Benachrichtigung über Änderung der Schiedsrichtereinteilung auswählen!');
        return false;
      }

      Common.cloneInputAndSelect($formGame, $('#dialog-notify-content :input'));
      $formGame.dirtycheck('clean');
      $formGame.trigger('submit');
      $dialogNotify.modal('hide');
      return true;
    });

    $('#btn-save').on('click', function () {
      if (!$formGame.valid()) {
        validator.focusInvalid();
        return false;
      }

      const gamedateNew = getCurrentGameDate();

      if (_gamedateCurrent == null || _gamedateCurrent > _dateNow || gamedateNew == null || gamedateNew > _dateNow) {

        let showDialog = false;

        if (gamedateNew != _gamedateCurrent) {
          showDialog = true;
        }

        if (_refereeClubCurrent != $('#referee-club').val()
          || _refereeClubCurrent2 != $('#referee-club2').val()
          || _referee1Current != $('#referee1').val()
          || _referee2Current != $('#referee2').val()) {
          showDialog = true;
        }

        if (showDialog) {
          Common.showLoading($('#dialog-notify-content')[0], 'Benachrichtigungseinstellungen werden geladen...');
          $dialog_notify_btn_save.prop('disabled', true);
          $dialogNotify.modal('show');

          $.ajax({
            url: $dialogNotify.data('url'),
            type: 'POST',
            data: $('input[name=idSB], #unknown-gamedate, #game-date, #game-time, #referee-club, #referee-club2, #referee1, #referee2').serialize(),
            dataType: 'html',
            success: function (data) {
              $('#dialog-notify-content').html(data);
              $dialog_notify_btn_save.prop('disabled', false).trigger('focus');
            },
            error: Common.showJsonFailError
          });

          return false;
        }
      }

      $formGame.trigger('submit');
      return false;
    });

    $('#form-homechange').on('submit', function () {
      return confirm('Soll das Heimrecht wirklich getauscht werden?\nNicht gespeicherte Änderungen auf dieser Seite gehen dabei verloren.');
    });

  }

  entry_goals(): void {
    $('select').select2({
      dropdownAutoWidth: true,
      containerCssClass: ':all:'
    });

    //$('[name^=Tor_Minute]').spinner({ min: 0, max: 99, change: function() { $(this).change(); } });
    //$('[name^=Tor_Second]').spinner({ min: 0, max: 59, change: function() { $(this).change(); } });
    $('#form-goals').dirtycheck()
      .on('submit', function (this: HTMLFormElement) {
        Common.prepareForSubmit(this);
      });
  }

  entry_goalies(): void {
    $('#form-goalies').dirtycheck()
      .on('submit', function (this: HTMLFormElement) {
        Common.prepareForSubmit(this);
      });
  }

  entry_penalties(): void {
    $('select[name^=idStrafe]').select2({
      dropdownAutoWidth: true,
      containerCssClass: ':all:',
      matcher: function (params, data) {
        if ($.trim(params.term) === '') {
          return data;
        }
        return (data.text.toUpperCase().indexOf(params.term.toUpperCase()) == 0) ? data : null;
      }
    });

    $('select:not([name^=idStrafe])').select2({
      dropdownAutoWidth: true,
      containerCssClass: ':all:'
    });

    $('#form-penalties').dirtycheck({filter: ':input:not([name=count], [name=position])'})
      .on('submit', function (this: HTMLFormElement) {
        Common.prepareForSubmit(this);
      });

    $('.btn-add').click(function () {
      $('#form-add').append($(this).closest('div').find('input').clone()).submit();
    });

    $('.btn-delete')
      .click(function () {
        if (confirm('Soll diese Strafzeit wirklich gelöscht werden?')) {
          var $formDelete = $('#form-delete');
          $formDelete.find('[name=idTeam]').val($(this).data('id'));
          $formDelete.find('[name=Nummer]').val($(this).data('number'));
          $formDelete.submit();
        }
        return false;
      });

    // $('[name^=Min]').spinner({ min: 0, max: 99, change: function() { $(this).change(); } });
    // $('[name^=Sec]').spinner({ min: 0, max: 59, change: function() { $(this).change(); } });
    // $('[name=count]').spinner({ min: 1, max: 99 });
    // $('[name=position]').each(function() {
    //   var val = $(this).val();
    //   $(this).spinner({ min: 1, max: val });
    // });
  }

  entry_players(): void {

    function updatePlayerCount(idTeam: number) {
      const count = $('input[type=checkbox][name^="player[' + idTeam + ']"]:checked').length;
      $('#players-count-' + idTeam).html(count.toString());
    }

    function updatePosition($row: JQuery<HTMLElement>) {
      let backgroundColor = '';

      if ($row.find('input[type=checkbox]').is(':checked')) {
        switch ($row.find('select option:selected').val()) {
          case 'C':
            backgroundColor = '#FFFF7F';
            break;
          case 'A':
            backgroundColor = '#FFCC7F';
            break;
          case 'G':
            backgroundColor = '#B2FF99';
            break;
          case 'F':
            backgroundColor = '#99CCFF';
            break;
          default:
            backgroundColor = 'transparent';
            break;
        }
      }

      $row.css('background-color', backgroundColor);
    }

    function toggleUpPlayers($button: JQuery) {
      const $icon = $button.find('i.fas'),
        $rows = $('table[data-team=' + $button.data('team') + '] tr[data-up=1]');

      $icon.removeClass('fa-eye fa-eye-slash');

      if ($button.data('toggle') == 'visible') {
        $button.data('toggle', 'hidden');
        $button.find('span.text').html('Mögliche Hochspieler anzeigen');
        $icon.addClass('fa-eye');
        $rows.find('input[type=checkbox]:not(:checked)').closest('tr').hide();
      } else {
        $button.data('toggle', 'visible');
        $button.find('span.text').html('Nicht ausgewählte Hochspieler verstecken');
        $icon.addClass('fa-eye-slash');
        $rows.show();
      }
    }

    $('tr[data-up=1] input[type=checkbox]:not(:checked)').closest('tr').hide();

    $('input[type=checkbox]').on('click', function (this: HTMLElement) {
      const $row = $(this).closest('tr');

      if ($row.data('blocked') == 1) {
        alert('Sie können diesen Spieler nicht abwählen!\nEr ist bei den Toren, Assists oder Strafzeiten eingetragen.');
        return false;
      }

      updatePlayerCount($row.closest('table').data('team'));
      updatePosition($row);
      return true;
    });

    $('input[type=checkbox]:checked').each(function (this: HTMLElement) {
      updatePosition($(this).closest('tr'));
    });

    $('select').on('change', function (this: HTMLElement) {
      updatePosition($(this).closest('tr'));
    });

    $('.btn-toggle').on('click', function (this: HTMLElement) {
      toggleUpPlayers($(this));
    });

    $('#form-players').dirtycheck()
      .on('submit', function (this: HTMLFormElement) {
        Common.prepareForSubmit(this);
      });
  }

  index(): void {
    $('#table-games').DataTable({
      paging: false,
      info: false,
      searching: false,
      order: []
    });
  }
}
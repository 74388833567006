'use strict';

import * as Common from "../common";
import {ControllerBase} from "../controllerBase";

export class SuspensionController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_suspension_id': this.entry,
      'admin_suspension_create': this.entry,
      'admin_suspension': this.index
    };
  }

  entry(): void {
    let $formSuspension = $('#form-suspension'),
      $suspensionDate = $('#suspension-date'),
      $expiryDate = $('#ExpiryDate'),
      $gamesSuspended = $('#GamesSuspended');

    function onDurationTypeChanged() {
      $expiryDate.prop('disabled', true);
      $gamesSuspended.prop('disabled', true);

      let value = $('input[name="DurationType"]:checked').val();
      if (value == 1) {
        if (($gamesSuspended.val() as string).length == 0) $gamesSuspended.val(1);
        $gamesSuspended.prop('disabled', false);
      } else if (value == 2) {
        if (($expiryDate.val() as string).length == 0) $expiryDate.datepicker('setDate', new Date());
        $expiryDate.prop('disabled', false);
      }
    }

    $.validator.addMethod('isExpiryDateAfterSuspensionDate', function (/* value, element */) {
      let suspensionDate = null;

      if (($suspensionDate.val() as string).length > 0) {
        suspensionDate = $suspensionDate.datepicker('getDate');
      } else {
        suspensionDate = new Date();
      }

      let expiryDate = $expiryDate.datepicker('getDate');
      return suspensionDate.getTime() < expiryDate.getTime();
    });

    function initUICreate() {
      let $dialog_player = $('#dialog-player'),
        $dialog_player_form_search = $('#dialog-player-form-search'),
        $btn_dialog_next = $('#btn-dialog-next');
      let dialog_player_step = 1;

      let idSpieler = '0';
      let idSpiel = '0';
      let idTeam = '0';

      let htmlPlayer = '';
      let htmlSpiel = '';
      let htmlTeam = '';

      let gameDate = '';

      $('#dialog-player-search').keyup(function (event) {
        if (event.keyCode == 13) {
          $('#btn-search-players').click();
        }
      });

      $btn_dialog_next.on('click', function () {
        switch (dialog_player_step) {
          case 1:
            let $checked_player = $('input[name=player]:checked');
            if ($checked_player.length == 0) {
              alert('Bitte einen Spieler auswählen!');
              return;
            }

            idSpieler = $checked_player.val() as string;
            htmlPlayer = $checked_player.parent('td').next().children('label').html();
            break;

          case 2:
            let $checked_game = $('input[name=game]:checked');
            if ($checked_game.length == 0) {
              alert('Bitte eine Option auswählen!');
              return;
            }

            idSpiel = $checked_game.val() as string;
            if (idSpiel == '0') {
              gameDate = '';
              htmlSpiel = 'Kein Ligaspiel';
            } else {
              let $td = $checked_game.parent('td').next();
              htmlSpiel = $td.children('label').html();
              gameDate = htmlSpiel.substr(0, 10);
              $td = $td.next();
              htmlSpiel = htmlSpiel + '<br>' + $td.next().html() + ' - ' + $td.next().next().html() + '<br>' + $td.html();
            }
            break;

          case 3:
            let $checked_team = $('input[name=team]:checked');
            if ($checked_team.length == 0) {
              alert('Bitte ein Team auswählen!');
              return;
            }

            idTeam = $checked_team.val() as string;
            htmlTeam = $checked_team.parent('td').next().children('label').html();

            $('#idSpieler').val(idSpieler);
            $('#player').html(htmlPlayer);
            $('#idSpielbericht').val(idSpiel);
            $('#game').html(htmlSpiel);
            $('#idTeam').val(idTeam);
            $('#team').html(htmlTeam);

            if (gameDate != '') {
              if (($suspensionDate.val() as string).length == 0) {
                $suspensionDate.val(gameDate);
              }
            }

            $dialog_player.modal('hide');
            return;
        }

        $('#dialog-step' + (dialog_player_step + 1)).show();
        $('#dialog-step' + dialog_player_step).hide();

        dialog_player_step++;

        // after switching step
        switch (dialog_player_step) {
          case 2:
            let dialog_game_result = document.getElementById('dialog-game-result');
            Common.showLoading(dialog_game_result!, 'Lade Spielberichte...');
            $.ajax({
              url: '/admin/suspension/partial/game_list/' + idSpieler,
              dataType: 'html',
              success: function (data) {
                dialog_game_result!.innerHTML = data;
              },
              error: Common.showJsonFailError
            });

            break;
          case 3:
            $btn_dialog_next.html('Fertig');
            let dialog_team_result = document.getElementById('dialog-team-result');
            Common.showLoading(dialog_team_result!, 'Lade Teams...');
            $.ajax({
              url: '/admin/suspension/partial/team_list/' + idSpieler + '/' + idSpiel,
              dataType: 'html',
              success: function (data) {
                dialog_team_result!.innerHTML = data;
              },
              error: Common.showJsonFailError
            });
            break;
        }
      });


      $('#btn-search-players').on('click', function () {
        if (!$dialog_player_form_search.valid()) return false;

        let url = $dialog_player_form_search.attr('action');
        let dialog_player_result = document.getElementById('dialog-player-result');
        Common.showLoading(dialog_player_result!, 'Suche Spieler...');

        $.ajax({
          url: url,
          data: $dialog_player_form_search.serialize(),
          dataType: 'html',
          success: function (data) {
            dialog_player_result!.innerHTML = data;
          },
          error: Common.showJsonFailError
        });

        return false;
      });

      $dialog_player_form_search.validate({
        rules: {
          search: {required: true, minlength: 3}
        },
        messages: {
          search: 'Bitte mindestens 3 Zeichen für die Suche eingeben!'
        },
        submitHandler: function () {
          return false;
        }
      });

      $('#btn-select-player').on('click', function () {
        dialog_player_step = 1;
        $('div[id^=dialog-step]').hide();
        $('#dialog-step1').show();
        $btn_dialog_next.html('Weiter<i class="fas fa-chevron-right ml-2"></i>');
        $dialog_player.modal();
        $('#dialog-player-search').focus();
      });
    }

    function initUI() {
      $suspensionDate.datepicker();
      $expiryDate.datepicker();

      $formSuspension.dirtycheck()
        .validate({
          rules: {
            idSpieler: 'required',
            SuspensionDate: {required: true, dateDE: true},
            SuspensionType: 'required',
            DurationType: 'required',
            GamesSuspended: {required: 'input[type="radio"][name="DurationType"][value="1"]:checked', digits: true},
            ExpiryDate: {
              required: 'input[type="radio"][name="DurationType"][value="2"]:checked',
              dateDE: true,
              isExpiryDateAfterSuspensionDate: true
            }
          },
          messages: {
            idSpieler: 'Bitte einen Spieler auswählen!',
            SuspensionDate: {
              required: 'Bei \'Beginn der Sperre\' muss ein Datum angegeben werden!',
              dateDE: 'Das Datum bei \'Beginn der Sperre\' ist ungültig!'
            },
            SuspensionType: 'Bitte Vergehen auswählen!',
            DurationType: 'Bitte Dauer der Sperre angeben!',
            GamesSuspended: {
              required: 'Bitte die Anzahl der Spiele angeben!',
              digits: 'Die Anzahl der Spiele ist keine gültige Zahl!'
            },
            ExpiryDate: {
              required: 'Bei \'Gesperrt bis zum\' muss ein Datum angegeben werden!',
              dateDE: 'Das Datum bei \'Gesperrt bis zum\' ist ungültig!',
              isExpiryDateAfterSuspensionDate: 'Das Datum \'Beginn der Sperre\' muss vor dem Datum \'Gesperrt bis zum\' liegen!'
            }
          }
        });

      $('#btn-delete').on('click', function () {
        if (confirm('Soll die Sperre wirklich gelöscht werden?')) {
          $formSuspension.dirtycheck('clean');
          $('#form-suspension-delete').submit();
        }

        return false;
      });

      $('input[name=DurationType]').on('change', function () {
        onDurationTypeChanged();
      });

      onDurationTypeChanged();
    }


    if ($formSuspension.data('create') == 1) {
      initUICreate();
    }

    initUI();
  }

  index(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          order: [[3, 'desc']],
          language: {
            searchPlaceholder: 'Gesperrten Spieler suchen...'
          }
        }
      );
  }
}
'use strict';

import * as Common from './common';

import {SecureController} from "./secure/secureController";
import {ClubController} from "./club/clubController";
import {ResultsController} from "./results/resultsController";

import {ClubController as AdminClubController} from "./admin/clubController";
import {ContactController} from "./admin/contactController";
import {DocumentController} from "./admin/documentController";
import {GameController} from "./admin/gameController";
import {IshdController} from "./admin/ishdController";
import {LeagueController} from "./admin/leagueController";
import {LocationController} from "./admin/locationController";
import {NewsController} from "./admin/newsController";
import {PlayerController} from "./admin/playerController";
import {RefereeController} from "./admin/refereeController";
import {SuspensionController} from "./admin/suspensionController";
import {TeamController} from "./admin/teamController";
import {UserController} from "./admin/userController";


function init() {
  $('[autofocus]:first').trigger('focus');
  $.fn.select2.defaults.set('theme', 'bootstrap4');
  $.fn.select2.defaults.set('language', 'de');
}

function initJqueryValidation() {
  $.validator.setDefaults({
    ignore: '',
    onkeyup: false,
    errorClass: 'invalid-feedback',
    errorPlacement: function (error, element) {
      let ex = element.nextAll('.invalid-feedback').first();
      if (ex.length > 0) {
        ex.html(error.html());
      } else {
        error.insertAfter(element);
      }
    },
    highlight: function (element) {
      $(element).addClass('is-invalid');
    },
    unhighlight: function (element) {
      $(element).removeClass('is-invalid');
    },
    submitHandler: function (form) {
      Common.prepareForSubmit(form);
      return true;
    }
  });

  $.validator.addMethod(
    'dateDE',
    function (value: string) {
      if (!/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(value)) {
        return false;
      }

      let comp = value.split('.');
      if (comp.length !== 3) {
        return false;
      }

      let d = parseInt(comp[0], 10);
      let m = parseInt(comp[1], 10);
      let y = parseInt(comp[2], 10);
      let date = new Date(y, m - 1, d);
      return (date.getFullYear() == y && date.getMonth() + 1 == m && date.getDate() == d);
    },
    'Das Datum muss im Format dd.mm.yyyy angegeben werden.'
  );

}

function initDatatables() {
  $.extend($.fn.dataTable.ext.oStdClasses, {
    sFilterInput: 'form-control'
  });

  $.extend($.fn.dataTable.ext.classes, {
    sInfo: 'dataTables_info pt-0',
    sFilter: 'dataTables_filter mr-4',
    sPaging: 'dataTables_paginate d-inline-block pagination-sm paging_',
    sLength: 'dataTables_length d-inline-block mr-2'
  });

  $.extend($.fn.dataTable.defaults, {
    dom: '<"form-inline mb-2"fi>rt<"mt-3"lp>',
    pagingType: 'full_numbers',
    pageLength: 25,
    lengthMenu: [25, 50, 100],
    language: {
      search: '_INPUT_',
      lengthMenu: '_MENU_',
      info: '_START_ bis _END_ von _TOTAL_ Einträgen',
      infoEmpty: '0 Einträge',
      infoFiltered: '(gefiltert aus _MAX_ Einträgen insgesamt)',
      zeroRecords: 'Keine passenden Einträge gefunden',
      paginate: {
        first: '&laquo;',
        last: '&raquo;',
        next: '&rsaquo;',
        previous: '&lsaquo;'
      }
    },
    drawCallback: function(settings: any) {
      if (settings._iDisplayLength >= settings.fnRecordsDisplay()) {
        $(settings.nTableWrapper).find('.dataTables_paginate').parent().hide();
      }
    }
  });
}

function initBootstrapDatepicker(): void {
  $.fn.datepicker.defaults.language = 'de';
  $.fn.datepicker.defaults.autoclose = true;
  $.fn.datepicker.defaults.daysOfWeekHighlighted = '06';
  $.fn.datepicker.defaults.todayHighlight = true;
}

function initPage(): void {
  let route: string = $('body').data('route');
  let processed = false;
  console.log(`Route: ${route}`);

  const controllers: {[key: string]: any} = {
      'secure': SecureController,
      'club': ClubController,
      'results': ResultsController,

      'admin_club': AdminClubController,
      'admin_contact': ContactController,
      'admin_document': DocumentController,
      'admin_game': GameController,
      'admin_ishd': IshdController,
      'admin_league': LeagueController,
      'admin_location': LocationController,
      'admin_news': NewsController,
      'admin_player': PlayerController,
      'admin_referee': RefereeController,
      'admin_suspension': SuspensionController,
      'admin_team': TeamController,
      'admin_user': UserController
    };

  for (let key in controllers) {
    if (route.startsWith(key)) {
      if ((new controllers[key]()).process(route)) {
        processed = true;
        break;
      }
    }
  }

  if (!processed) {
    console.log('No code...');
  }

  // for (let key in controllers) {
  //   (new controllers[key]()).checkRoutesStartWithKey(key);
  // }
}

$(function () {
  init();
  initJqueryValidation();
  initDatatables();
  initBootstrapDatepicker();
  initPage();
});
'use strict';

import {ControllerBase} from "../controllerBase";

export class PlayerController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_player_duplicates': this.duplicates,
      'admin_player_id': this.entry
    };
  }

  duplicates(): void {
    $('button').on('click', function () {
      let mergeTo = $(this).data('id');
      $(this).closest('form').find('input[type=hidden][name=mergeTo]').val(mergeTo);
    });
  }

  entry(): void {
    $('input[type=text][name=DateOfBirth]').datepicker();

    $('#form-player')
      .dirtycheck()
      .validate({
        rules: {
          FirstName: 'required',
          LastName: 'required',
          DateOfBirth: {required: false, dateDE: true}
        },
        messages: {
          FirstName: 'Bitte Vornamen angeben!',
          LastName: 'Bitte Nachnamen angeben!',
          DateOfBirth: {
            dateDE: 'Das Geburtsdatum ist ungültig!'
          }
        }
      });
  }
}
'use strict';

import * as Common from '../common';

export class AddItemsControl {

  private $formItems = $('#form-items');
  private $itemsInSelect = $('#select-items-in');
  private $btnItemsRemove: JQuery<HTMLElement>;
  private $itemsCount = $('#items-count');

  private items = this.$formItems.data('items');

  private openButton = $('#btn-open-dialog');

  private itemsSelect = $('#dialog-add-items-select-items');
  private itemsFilter = $('#dialog-add-items-text-filter');
  private buttonAdd = $('#dialog-add-items-button-add');

  constructor() {
    this.$btnItemsRemove = $('#btn-items-remove');
    this.init();
  }

  private init(): void {
    let control = this;

    control.$formItems.on('submit', function (this: HTMLFormElement) {
      Common.prepareForSubmit(this);
      control.$itemsInSelect.find('option').each(function () {
        control.$formItems.append($('<input>')
          .attr('type', 'hidden')
          .attr('name', 'Items[]')
          .val($(this).val() as any));
      });
    });

    control.$formItems.dirtycheck({filter: 'input'});

    control.$btnItemsRemove.on('click', function () {
      control.$itemsInSelect.find('option:selected').remove();
      control.$formItems.dirtycheck('dirty');
      updateBtnItemsRemove();
      updateItemsCount();
    });

    control.$itemsInSelect.on('change', updateBtnItemsRemove);

    updateBtnItemsRemove();
    updateItemsCount();

    control.buttonAdd.prop('disabled', true);


    control.itemsSelect.on('change', updateButtonCloseEnalbed);

    control.openButton.on('click', updateItems);

    control.itemsFilter.on('change keyup', function () {
      updateItems();
    });

    control.buttonAdd.on('click', function () {
      control.$itemsInSelect.append(control.itemsSelect.find('option:selected').remove().prop('selected', false));
      updateButtonCloseEnalbed();
      sortItemsInOptions();
      control.$formItems.dirtycheck('dirty');
      updateItemsCount();
      control.itemsFilter.trigger('focus');
    });

    $('#dialog-add-items').on('shown.bs.modal', function () {
      control.itemsFilter.trigger('focus');
    });

    function updateBtnItemsRemove(): void {
      control.$btnItemsRemove.prop('disabled', control.$itemsInSelect.find('option:selected').length == 0);
    }

    function updateItemsCount(): void {
      control.$itemsCount.html(control.$itemsInSelect.find('option').length.toString());
    }

    function sortItemsInOptions(): void {
      let options = control.$itemsInSelect.find('option');
      let values = options.map(function (_, o) {
        return parseInt(o.value);
      }).get();

      let selectedItems = $.grep(control.items, function (item: any) {
        return values.indexOf(item.id) != -1;
      });

      options.each(function (i, o: any) {
        o.value = selectedItems[i].id;
        $(o).text(selectedItems[i].name);
      });
    }

    function updateItems(): void {
      control.itemsSelect.empty().scrollTop(0);
      let search = $.trim(control.itemsFilter.val() as string);
      let regex = new RegExp(search, 'gi');
      let values = control.$itemsInSelect.find('option').map(function () {
        return parseInt($(this).val() as string);
      }).get();

      $.each(control.items, function (i) {
        let option = control.items[i];
        if (option.name.match(regex) !== null && values.indexOf(option.id) == -1) {
          control.itemsSelect.append(
            $('<option>').text(option.name).val(option.id)
          );
        }
      });

      if (control.itemsSelect.children().length === 1) {
        (control.itemsSelect.children().get(0) as HTMLOptionElement).selected = true;
      }

      control.itemsSelect.scrollTop(0);

      updateButtonCloseEnalbed();
    }

    function updateButtonCloseEnalbed(): void {
      control.buttonAdd.prop('disabled', control.itemsSelect.find('option:selected').length == 0);
    }
  }
}
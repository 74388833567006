'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class LocationController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_location_id': this.entry,
      'admin_location_create': this.entry,
      'admin_location': this.index
    };
  }

  entry(): void {
    $('#form-location').dirtycheck({message: 'Der Spielort wurde noch nicht gespeichert!'})
      .validate({
        rules: {
          Name: 'required',
          Ort: 'required',
          PLZ: {range: [10000, 99999]}
        },
        messages: {
          Name: 'Bitte einen Namen eingeben!',
          Ort: 'Bitte einen Ort eingeben!',
          PLZ: 'Die Postleitzahl ist ungültig!'
        }
      });

    $('#link-maps-address').on('click', function () {
      let $url = 'http://maps.google.de/maps?q=';
      $url = $url + $('#street').val() + ' ' + $('#postal-code').val() + ' ' + $('#city').val();
      $(this).attr('href', $url);
    });

    $('#link-maps-lat-lng').on('click', function () {
      let $url = 'http://maps.google.de/maps?ll=';
      $url = $url + $('#latitude').val() + ',' + $('#longitude').val();
      $(this).attr('href', $url);
    });

    $('#link-geocode').on('click', function () {
      let $url = 'http://maps.googleapis.com/maps/api/geocode/json?address=';
      $url = $url + $('#street').val() + ' ' + $('#postal-code').val() + ' ' + $('#city').val();
      $url = $url + '&sensor=false&region=de';
      $(this).attr('href', $url);
    });
  }

  index(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          language: {
            searchPlaceholder: 'Spielorte suchen...'
          }
        }
      );
  }
}
'use strict';

import * as Common from './common';
import {DataFormat} from 'select2';

export class LazyDataAdapter {
  private readonly cacheDataString = 'select2-cache-data';
  private $cacheElement: JQuery;
  private lastTerm: string = '';

  constructor(decorated: Function, $element: JQuery, options: any) {
    decorated.call(this, $element, options);
    const cacheElementSelector = $element.data('select2-cache-element');
    this.$cacheElement = (cacheElementSelector !== undefined) ? $(cacheElementSelector) : $element;
  }

  query(decorated: Function, params: any, callback: any) {
    this.lastTerm = params.term !== undefined ? params.term : '';

    if (this.$cacheElement.data(this.cacheDataString) == null) {
      $.getJSON(this.$cacheElement.data('source-url'), '', data => {
        this.$cacheElement.data(this.cacheDataString, data.result);
        callback(this.getFilteredResults(this.lastTerm));
      })
        .fail(Common.showJsonFailError);
    } else {
      callback(this.getFilteredResults(this.lastTerm));
    }
  };

  getFilteredResults = (term: string) => {
    const data = $.grep(this.$cacheElement.data(this.cacheDataString), (item: DataFormat) => {
      return item.text.toUpperCase().indexOf(term.toUpperCase()) >= 0;
    });
    return {results: data};
  };
}
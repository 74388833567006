'use strict';

import {ControllerBase} from "../controllerBase";

export class ResultsController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'results_selectclubs': this.club_selection,
      'results_club_id_game_id': this.entry
    }
  }

  club_selection(): void {
    let $clubCount = $('#club-count');

    $('#club-selection tbody tr').on('click', function (event) {
      if (!(event.target instanceof HTMLInputElement)) {
        $(this).find('input[type=checkbox]').trigger('click');
      }
    });

    function updateSelectedBackground(checkbox: HTMLInputElement) {
      let $row = $(checkbox).closest('tr');
      if (checkbox.checked) {
        $row.addClass('table-success');
      } else {
        $row.removeClass('table-success');
      }
    }

    function updateSelectedClubsCount() {
      $clubCount.html($('#club-selection input[type=checkbox]:checked').length.toString());
    }

    $('#club-selection input[type=checkbox]')
      .on('click', function (this: HTMLInputElement) {
        updateSelectedBackground(this);
        updateSelectedClubsCount();
      })
      .each(function () {
        updateSelectedBackground(this as HTMLInputElement);
      });

    $('#form-club-selection').validate({
      errorPlacement: function (error) {
        $('#error').append(error);
      },
      rules: {
        'ClubsResultsIds[]': {
          required: true,
          maxlength: 3
        }
      },
      messages: {
        'ClubsResultsIds[]': {
          required: 'Bitte mindestens einen Verein auswählen',
          maxlength: 'Bitte maximal drei Vereine auswählen'
        }
      }
    });
  }

  entry(): void {
    let $goalsHome = $('#goals-home'),
      $goalsAway = $('#goals-away'),
      $winner = $('#winner'),
      $gamePlayedPanel = $('#game-played');

    // TODO: implement spinner for goals report
    //$('input[type=text]').spinner({min: 0, max: 99});

    $('#chk-game-not-played').on('click', function () {
      $validator.resetForm();
      $gamePlayedPanel.slideToggle();
    });

    function getNumber(value: string): Number | undefined {
      if (value.length == 0) {
        return undefined;
      }

      let num = Number(value);
      return isNaN(num) ? undefined : num;
    }

    function updateWinner() {
      let winner = '',
        goalsHome = getNumber($goalsHome.val() as string),
        goalsAway = getNumber($goalsAway.val() as string);

      if (goalsHome != undefined && goalsAway != undefined) {
        if (goalsHome > goalsAway) {
          winner = $('#teamHomeName').html();
        } else if (goalsAway > goalsHome) {
          winner = $('#teamAwayName').html();
        } else {
          winner = 'Unentschieden';
        }
      }

      if (winner.length > 0) winner = 'Sieger: ' + winner;
      else winner = '&nbsp;';

      $winner.html(winner);
    }

    updateWinner();

    $goalsHome.on('keyup', updateWinner)/*.on('spinstop', updateWinner)*/;
    $goalsAway.on('keyup', updateWinner)/*.on('spinstop', updateWinner)*/;

    jQuery.validator.addMethod('oneGoalDifference', function (this: any, value, element, params) {
      if ((typeof params === 'string' && $(params).length == 0)
        || this.optional(element) || !$(element).is(':checked')) return true;
      return Math.abs(($goalsHome.val() as number) - ($goalsAway.val() as number)) == 1;
    }, 'Nach Penaltyschießen kann nur bei einem Tor Unterschied ausgewählt werden!');

    let $validator = $('#form-result').validate({
      rules: {
        goalsHome: {
          required: '#chk-game-not-played:unchecked',
          range: [0, 99]
        },
        goalsAway: {
          required: '#chk-game-not-played:unchecked',
          range: [0, 99]
        },
        afterPenalty: {
          oneGoalDifference: '#chk-game-not-played:unchecked'
        },
        reportComment: {
          required: '#chk-game-not-played:checked'
        }
      },
      messages: {
        goalsHome: 'Bitte eine Zahl eingeben',
        goalsAway: 'Bitte eine Zahl eingeben',
        reportComment: 'Bitte einen Grund für das nicht ausgetragene Spiel angeben'
      }
    });
  }
}
'use strict';

import * as Common from '../common';
import {ControllerBase} from "../controllerBase";

export class TeamController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_team': this.index,
      'admin_team_id': this.entry_details,
      'admin_team_id_details': this.entry_details,
      'admin_team_create': this.entry_details,
      'admin_team_id_players': this.entry_players
    }
  }

  entry_details(): void {
    $('#altersklasse, #clubrank').select2();
    $('#club').select2({placeholder: 'Verein auswählen...'});
    $('#location').select2({allowClear: true, placeholder: 'Spielort auswählen...'});

    $('#form-team')
      .dirtycheck({message: 'Das Team wurde noch nicht gespeichert!'})
      .validate({
        rules: {
          TeamName: 'required',
          idVerein: 'required'
        },
        messages: {
          TeamName: 'Bitte einen Teamnamen angeben',
          idVerein: 'Es muss ein Verein ausgewählt werden'
        }
      });
  }

  entry_players(): void {
    $('table')
      .on('init.dt', Common.setFocusDataTables)
      .DataTable({
          order: [[1, 'asc']],
          language: {
            searchPlaceholder: 'Spieler suchen...'
          }
        }
      );
  }

  index(): void {
    let chkShowAll = $('#chk-show-all');
    let subtitle = $('#subtitle');
    let additionalFilters = $('#additional-filter');

    $.fn.dataTable.ext.search.push(
      function (settings: any, data: any) {
        return chkShowAll.is(':checked') || data[4] == "1";
      }
    );

    let table = $('table').on('init.dt', function () {
      Common.setFocusDataTables();
      $('.dataTables_filter').parent().append(additionalFilters);
      additionalFilters.show();
    }).DataTable({
        language: {
          searchPlaceholder: 'Teams suchen...'
        }
      }
    );

    function updateSubtitle(): void {
      subtitle.html(chkShowAll.is(':checked') ? 'Alle Teams' : 'Nur aktive Teams');
    }

    updateSubtitle();

    chkShowAll.on('change', function () {
      updateSubtitle();
      table.draw();
    });
  }
}
'use strict';

import {IDictionary} from "../common";
import {AddItemsControl} from "../controls/addItemsControl";
import {ControllerBase} from "../controllerBase";

export class LeagueController extends ControllerBase {

  constructor() {
    super();
    this.routes = {
      'admin_league_id': this.entry_details,
      'admin_league_id_details': this.entry_details,
      'admin_league_year_create': this.entry_details,
      'admin_league_id_games_import_step2': this.entry_games_import_step2,
      'admin_league_id_permissions': this.entry_permissions,
      'admin_league_id_teams': this.entry_teams,
      'admin_league': this.index,
      'admin_league_year': this.index,
    };
  }

  entry_details(): void {

    $('#idLeagueGroup').select2({
      placeholder: 'Ligagruppe auswählen...',
      allowClear: true
    });

    $('#idSpielgruppenleiter').select2({
      placeholder: 'Spielgruppenleiter auswählen...',
      allowClear: true
    });

    $('#idAltersklasse, #league-type, #sortrank').select2();

    $('#form-league').dirtycheck()
      .validate({
        rules: {
          LigaName: 'required'
        },
        messages: {
          LigaName: 'Bitte einen Liganamen angeben!'
        }
      });
  }

  entry_games_import_step2(): void {

    let selects = $('select.team');
    let gameWarnings = $('#game-warnings');

    let teams: any = selects.first().find('option').map(function (this: any) {
      if (this.value == '') return null;
      return {id: this.value, text: this.text};
    }).get();

    let gameRows = $('tr.game');

    function checkGames(): void {
      let games: IDictionary<string> = {};
      let warnings: string = "";

      for (let t1 of teams) {
        for (let t2 of teams) {
          if (t1.id != t2.id) {
            games[t1.id + '_' + t2.id] = t1.text + ' - ' + t2.text;
          }
        }
      }

      gameRows.each(function () {
        let row = $(this);
        let index = row.data('index');

        let idTeamHeim = row.find('[name="idTeamHeim[' + index + ']"]').val();
        let idTeamGast = row.find('[name="idTeamGast[' + index + ']"]').val();

        //console.debug(idTeamHeim + ' - ' + idTeamGast);

        if (idTeamHeim == '' || idTeamGast == '') {
          warnings += 'Select teams for index ' + index + '<br>';
          return;
        }

        if (idTeamHeim == idTeamGast) {
          warnings += 'Home and away are identical for index ' + index + ': ' + idTeamHeim + '<br>';
          return;
        }

        let key = idTeamHeim + '_' + idTeamGast;

        if (games[key] == undefined) {
          warnings += 'Duplicate game at index ' + index + '<br>';
          return;
        }

        delete (games[key]);
      });

      console.debug(games);

      for (let k in games) {
        if (games[k].length > 0) {
          warnings += 'Missing game: ' + games[k] + '<br>';
        }
      }

      gameWarnings.html(warnings);
      if (warnings.length > 0) gameWarnings.show();
      else gameWarnings.hide();
    }

    checkGames();

    $('#location').select2();

    $('form').validate({
      rules: {
        idSpielort: 'required',
      },
      messages: {
        idSpielort: 'Bitte Spielort auswählen'
      }
    });

    $('select.team').on('change', function () {
      $(this).closest('td').removeClass('table-danger');
      checkGames();
    })
      .each(function (this: any) {
        $(this).rules('add', {required: true, messages: {required: 'Team auswählen!'}})
      });
  }

  entry_permissions(): void {
    new AddItemsControl();
  }

  entry_teams(): void {
    new AddItemsControl();
  }

  index(): void {
    let $form = $('#form-leagues');
    let action: string = $form.attr('action') as string;

    $('button.action-moveup, button.action-movedown').on('click', function (e) {
      e.preventDefault();
      let button = $(this);

      action += button.closest('tr').data('idleague') + '/';

      if (button.hasClass('action-moveup')) action += 'moveup/';
      if (button.hasClass('action-movedown')) action += 'movedown/';

      $form.attr('action', action);
      $form.trigger('submit');
    });
  }
}